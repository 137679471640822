import i18n from "i18next";
import { initReactI18next } from "react-i18next";

// Archivos de traducción
import es from "./locales/es.json";
import en from "./locales/en.json";
import fr from "./locales/fr.json";
import cn from "./locales/cn.json";

const resources = {
  es: { translation: es },
  en: { translation: en },
  fr: { translation: fr },
  cn: { translation: cn },
};

i18n.use(initReactI18next).init({
  resources,
  lng: "es", // Idioma predeterminado
  fallbackLng: "es", // Idioma de respaldo
  interpolation: { escapeValue: false }, // React ya escapa los valores por defecto
});

export default i18n;
