import apiClient from "../services/apiClient";

const BusinessRepository = {
  getAllBusinesses: async (params) => {
    const queryParams = Object.entries(params)
      .filter(([_, value]) => value != null)
      .map(([key, value]) => `${key}=${value}`)
      .join("&");

    const endpoint = queryParams ? `businesses?${queryParams}` : "businesses";
    
    try {
      const response = await apiClient.get(endpoint);
      return response.data;
    } catch (error) {
      console.error("Error fetching businesses:", error);
      throw error;
    }
  },
};

export default BusinessRepository;



