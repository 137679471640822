import React, { useEffect,useState } from "react";
import { useSelector } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useTranslation } from "react-i18next";
import {
  faBuilding,
  faRoute,
  faCircleInfo,
} from "@fortawesome/free-solid-svg-icons";
import RouteMapModal from "../../components/RouteMapModal";

const Establecimiento = ({ business, apiKey, lang }) => {
  const { t, i18n } = useTranslation();
  
  const { latitude, longitude } = useSelector((state) => state.location); // Ubicación del usuario
  const [isMapOpen, setIsMapOpen] = useState(false); // Estado para mostrar/ocultar el mapa
  const phoneNumber = `${business.phonePostalCode}${business.phoneNumber}`;
  const distance = business.distance<1 ?  `${(business.distance*1000).toFixed(1)}mt` :  `${business.distance.toFixed(1)}km`;
  const walking = business.distance<2 ? true : false;
  const whatsappMessage =
    "¡Hola! Vengo desde https://paracas.online y quiero solicitar informacion de su servicios turisticos. ¿Me ayudas?";
    
  // Cambiar idioma según el valor de lang
  useEffect(() => {
    if (lang && i18n.language !== lang) {
      i18n.changeLanguage(lang); // Cambia el idioma dinámicamente
    }
  }, [lang, i18n]);

  const handleOpenMap = () => {
    if (!latitude || !longitude) {
      return;
    }
    setIsMapOpen(true);
  };

  const handleCloseMap = () => {
    setIsMapOpen(false);
  };

  // Selección de denominación según el idioma
  const singularDenomination = (() => {
    const langKey = `singularDenomination${lang.charAt(0).toUpperCase() + lang.slice(1)}`; // Generar la clave dinámicamente, p. ej., "singularDenominationEn"
    return business.type[langKey] || business.type.singularDenominationEs; // Fallback al español si no está definido
  })();

  return (
    <div className="col-xxl-8 col-xl-8 col-lg-8 col-md-8 col-sm-8 col-xs-12 card mt-1">
      <div className="card-body">
        {/* Información principal del negocio */}
        <div className="row justify-content-center">
          <div className="col-12 d-flex justify-content-start align-items-center">
            <img
              src={
                business?.imageUrl ||
                "https://upload.wikimedia.org/wikipedia/commons/a/a3/Image-not-found.png"
              }
              className="rounded-circle"
              width="35"
              height="35"
              alt={business.businessName}
            />
            <h5 className="text-uppercase text-start fw-bold text-royal-blue m-0 p-0">
              &nbsp;{business.businessName}
            </h5>
          </div>
        </div>
        <div className="row justify-content-start">
          <div className="col-9 d-flex flex-column mb-3">
            <p className="text-black text-uppercase fw-bold m-0 p-0">
              {singularDenomination}
            </p>
            <p className="p-0 m-0">
              <FontAwesomeIcon icon={faBuilding} className="me-2" /> {business.address}
            </p>  
            {business.institutions?.length > 0 && (
              <div>
                <p className="text-muted m-0 p-0">
                  <span className="text-dark-blue">{t("authorized")}</span>
                </p>
                <div className="d-flex flex-nowrap">
                  {business.institutions.map((institution, index) => (
                    <img
                      key={index}
                      src={institution.url}
                      className="img-fluid me-2 rounded-circle"
                      width="50"
                      alt={institution.name || "Institution Logo"}
                    />
                  ))}
                </div>
              </div>
            ) }
          </div>
        </div>

        {/* Botones */}
        <div className="mt-3"></div>
        <div className="row justify-content-center">
          <div className="col-12 d-flex flex-wrap">
            {/*<button className="btn btn-outline-primary mx-1 mt-1">
              <FontAwesomeIcon icon={faSuitcase} /> Servicios
            </button>
            <button className="btn btn-outline-primary mx-1 mt-1">
              <FontAwesomeIcon icon={faComment} /> Comentarios
            </button>*/}
            <button
              className="btn btn-outline-primary mx-1 mt-1"
              onClick={handleOpenMap} // Abre el modal del mapa
            >
              <FontAwesomeIcon icon={faRoute} /> {t("distance")} - {distance}
            </button>
            {business.phoneNumber!=null && (
                <a
                href={`https://wa.me/${phoneNumber}?text=${encodeURIComponent(whatsappMessage)}`}
                className="btn btn-success mx-1 mt-1"
                target="_blank"
                rel="noopener noreferrer"
              > 
                <img
                  src={"/wa.jpeg"}
                  className="img-fluid me-2 rounded-circle"
                  width="30"
                  alt={""}
                />
                {t("contact")}
              </a>
            )}
            
          </div>
        </div>
      </div>

      {/* Modal del mapa con la ruta */}
      {isMapOpen && (
        <RouteMapModal
          apiKey="AIzaSyAOZe_kwgfg12mP_c7D1oZYmFbyAagBpHs"
          origin={{ lat: latitude, lng: longitude }} // Ubicación del usuario
          destination={{ lat: business.addressLat, lng: business.addressLng }} // Ubicación del negocio
          onClose={handleCloseMap} // Cierra el modal
          walking={walking}
        />
      )}
    </div>
  );
};

export default Establecimiento;


