import React from 'react';
import { useTranslation } from "react-i18next";
import { useSearchParams } from "react-router-dom";

const LangModal = ({onClose}) => {
    const { i18n } = useTranslation();
    const [searchParams, setSearchParams] = useSearchParams();

    const handleLanguageChange = (lang) => {
        i18n.changeLanguage(lang); // Cambia el idioma en i18n
        setSearchParams({ lang }); // Actualiza el parámetro "lang" en la URL
        onClose(); // Cierra el modal
    };

    return (
        <div style={styles.container} className="">
          <div className="d-flex flex-column mb-3 justify-content-center">
            <img src={"/langs/es.png"} className="img-fluid" onClick={() => handleLanguageChange("es")} width="120" alt="Logo" />
            <img src={"/langs/en.png"} className="img-fluid" onClick={() => handleLanguageChange("en")} width="120" alt="Logo" />
            <img src={"/langs/fr.png"} className="img-fluid" onClick={() => handleLanguageChange("fr")} width="120" alt="Logo" />
            <img src={"/langs/cn.png"} className="img-fluid" onClick={() => handleLanguageChange("cn")} width="120" alt="Logo" />
          </div>
        </div>
    );
}

const styles = {
    container: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100vh',
        width: '100vw',
        backgroundColor: '#fff',
        fontFamily: 'Arial, sans-serif',
        margin: 0,
        overflow: 'hidden',
        position: 'fixed', // Asegura que cubra toda la pantalla
        top: 0,
        left: 0,
        zIndex: 9999, // Garantiza que esté sobre otros elementos
    },
};

export default LangModal;