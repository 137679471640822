import React, { useState, useEffect, useCallback } from "react";
import ReactModal from "react-modal";
import { GoogleMap, useLoadScript } from "@react-google-maps/api";
import { useDispatch, useSelector } from "react-redux";
import { setLocation, setLocationError } from "../store/slices/locationSlice";
import { setSearch } from "../store/slices/searchSlice";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCrosshairs } from "@fortawesome/free-solid-svg-icons";
import axios from "axios";
import { useTranslation } from "react-i18next";

ReactModal.setAppElement("#root");

function debounce(func, wait) {
  let timeout;
  return (...args) => {
    clearTimeout(timeout);
    timeout = setTimeout(() => func(...args), wait);
  };
}

const GoogleMapModal = ({ isOpen, onClose, onConfirm, apiKey, lang }) => {
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();
  const { latitude, longitude, error } = useSelector((state) => state.location);

  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey: apiKey,
  });

  const [mapHeight, setMapHeight] = useState(window.innerHeight);
  const [mapRef, setMapRef] = useState(null);

  // Variables de estado temporales (lat/lng)
  const [tempLatitude, setTempLatitude] = useState(latitude);
  const [tempLongitude, setTempLongitude] = useState(longitude);

  // Dirección que se muestra en el input
  const [customAddress, setCustomAddress] = useState("");

  useEffect(() => {
    const handleResize = () => setMapHeight(window.innerHeight);
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  // Cambiar idioma según el valor de lang
  useEffect(() => {
    if (lang && i18n.language !== lang) {
      i18n.changeLanguage(lang); // Cambia el idioma dinámicamente
    }
  }, [lang, i18n]);

  useEffect(() => {
    if(latitude==null && longitude==null){
      const defaultLat = -13.832750730195295; // Reemplaza con latitud por defecto
      const defaultLng = -76.24846956871379; // Reemplaza con longitud por defecto
      setTempLatitude(defaultLat);
      setTempLongitude(defaultLng);
    }else{
      // Si la ubicación está en el store, úsala
      setTempLatitude(latitude);
      setTempLongitude(longitude);
    }
  }, [dispatch]);

  const fetchAddress = async (position) => {
    try {
      const response = await axios.get(
        `https://maps.googleapis.com/maps/api/geocode/json?latlng=${position.latitude},${position.longitude}&key=${apiKey}`
      );
      const address =
        response.data.results[0]?.formatted_address || "Ubicación desconocida";
      setCustomAddress(address);
    } catch (error) {
      console.error("Error al obtener la dirección:", error);
      setCustomAddress("Ubicación desconocida");
    }
  };

  const updateLocationDebounced = useCallback(
    debounce(async (position) => {
      await fetchAddress(position);
    }, 300),
    []
  );

  const handleMyPosition = () =>{
    navigator.geolocation.getCurrentPosition(
      (pos) => {
        // Si quieres actualizar la posición inicial según la geolocalización del usuario,
        // podrías descomentar estas líneas
        const { latitude, longitude } = pos.coords;
        setTempLatitude(latitude);
        setTempLongitude(longitude);
      },
      () => {
        dispatch(
          setLocationError(
            "No se pudo obtener tu ubicación. Cargando ubicación por defecto."
          )
        );
      }
    );
  }

  // Cuando termina el drag/zoom (idle), actualizamos lat/lng
  const handleIdle = () => {
    if (!mapRef) return;
    const center = mapRef.getCenter();
    const position = {
      latitude: center.lat(),
      longitude: center.lng(),
    };
    setTempLatitude(position.latitude);
    setTempLongitude(position.longitude);
    updateLocationDebounced(position);
  };

  // Al confirmar, se hace el dispatch a Redux con la posición final
  const handleConfirm = () => {
    const position = { latitude: tempLatitude, longitude: tempLongitude };

    // 1. Guardar en Redux la nueva ubicación
    dispatch(setLocation(position));

    // 2. Reiniciar el paginado (skip=0) o lo que necesites
    dispatch(
      setSearch({
        skip: 0,
        limit: 50, // Ajusta según tu caso
        sort: "ASC", // Ajusta según tu caso
      })
    );

    // 3. Notificar al padre (si tu padre hace más lógica)
    onConfirm(position);

    // 4. Cerrar el modal
    onClose();
    setMapRef(null);
  };

  if (loadError) {
    console.error("Error al cargar Google Maps:", loadError);
    return <div>Error al cargar el mapa</div>;
  }

  if (!isLoaded) {
    return <div>Cargando mapa...</div>;
  }

  return (
    <ReactModal
      isOpen={isOpen}
      onRequestClose={onClose}
      contentLabel="Selecciona tu ubicación"
      style={{
        overlay: { backgroundColor: "rgba(0, 0, 0, 0.8)", padding: "0" },
        content: {
          position: "absolute",
          top: "0",
          left: "0",
          right: "0",
          bottom: "0",
          padding: "0",
          margin: "0",
          border: "none",
          borderRadius: "0",
          overflow: "hidden",
        },
      }}
    >
      <div
        style={{
          height: `${mapHeight}px`,
          width: "100vw",
          position: "relative",
        }}
      >
        {error && (
          <p style={{ color: "red", textAlign: "center", marginTop: "10px" }}>
            {error}
          </p>
        )}
        <GoogleMap
          mapContainerStyle={{ width: "100%", height: "100%" }}
          center={{
            lat: tempLatitude,
            lng: tempLongitude,
          }}
          zoom={19}
          options={{
            disableDefaultUI: true,
            zoomControl: true,
            gestureHandling: "greedy",
          }}
          onLoad={(map) => setMapRef(map)}
          onIdle={handleIdle}
        >
          <div
            style={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -100%)",
              zIndex: 10,
            }}
          >
            {/* Icono para mostrar el punto central */}
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
              fill="currentColor"
              width="40px"
              height="40px"
            >
              <path d="M3 2C3.552 2 4 2.448 4 3V20C4 20.552 3.552 21 3 21C2.448 21 2 20.552 2 20V3C2 2.448 2.448 2 3 2ZM6 4H17.985C19.037 4 20.015 4.497 20.58 5.35C21.001 5.995 21.02 6.758 20.622 7.421C20.225 8.083 19.375 8.5 18.414 8.5C19.375 8.5 20.225 8.917 20.622 9.579C21.02 10.242 21.001 11.005 20.58 11.65C20.015 12.503 19.037 13 17.985 13H6V4Z" />
            </svg>
          </div>
        </GoogleMap>

        {/* Input para mostrar la dirección formateada */}
        <div
          style={{
            position: "absolute",
            bottom: "120px",
            left: "50%",
            transform: "translateX(-50%)",
            width: "90%",
            backgroundColor: "#fff",
            borderRadius: "10px",
            padding: "10px",
            boxShadow: "0 2px 8px rgba(0, 0, 0, 0.1)",
          }}
        >
          <input
            type="text"
            placeholder="Crisantemos"
            value={customAddress}
            onChange={() => {}}
            style={{
              width: "100%",
              padding: "10px",
              border: "1px solid #ddd",
              borderRadius: "5px",
            }}
            readOnly
          />
        </div>
            
        {/* Botón de Confirmar */}
        <div
          style={{
            position: "absolute",
            bottom: "20px",
            left: "50%",
            transform: "translateX(-50%)",
            display: "flex",
            gap: "15px",
          }}
        >
          <button
            className="btn btn-primary"
            onClick={handleConfirm}
            style={{
              padding: "10px 20px",
              fontSize: "16px",
              borderRadius: "5px",
              backgroundColor: "#007bff",
              color: "#fff",
              border: "none",
              cursor: "pointer",
            }}
          >
            {t("confirm")}
          </button>
        </div>

        {/* Botón de Confirmar */}
        <div
          style={{
            position: "absolute",
            top: "20px",
            right: "2%",
          }}
        >
          <button
            className="btn btn-primary"
            style={{
              fontSize: "16px",
              cursor: "pointer",
            }}
            onClick={handleMyPosition}
          >
            <FontAwesomeIcon className="text-white text-10x" icon={faCrosshairs} />
          </button>
        </div>
      </div>
    </ReactModal>
  );
};

export default GoogleMapModal;
