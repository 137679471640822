import React from "react";

const Booking = () => {
  return (
    <div className="booking">
      <h1>Se procede a reservar</h1>
    </div>
  );
};

export default Booking;
