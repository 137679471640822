import React from 'react';

const Loading = ({ isLoading }) => {
    if (!isLoading) return null; // Si isLoading es false, no se renderiza nada

    return (
        <div style={styles.container} className="">
          <div className="flex flex-column">
            <p className="p-0"><span className="fs-lilita-one-regular text-center text-white text-9x">
                Paracas<span className="text-yellow">.online</span>
            </span></p>
            <p className="text-center p-0">Espere por favor...</p>
          </div>
        </div>
    );
};

const styles = {
    container: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100vh',
        width: '100vw',
        backgroundColor: '#2E9AFE',
        color: '#fff',
        fontFamily: 'Arial, sans-serif',
        margin: 0,
        overflow: 'hidden',
        position: 'fixed', // Asegura que cubra toda la pantalla
        top: 0,
        left: 0,
        zIndex: 9999, // Garantiza que esté sobre otros elementos
    },
};

export default Loading;
