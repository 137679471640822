import React, { useEffect, useState } from "react";
import { GoogleMap, DirectionsRenderer, useLoadScript } from "@react-google-maps/api";

const RouteMapModal = ({ apiKey,origin,destination, onClose, walking }) => {
  // Cargar la API de Google Maps con la clave proporcionada
  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey: apiKey,
  });

  const [directionsResponse, setDirectionsResponse] = useState(null);

  useEffect(() => {
    if (isLoaded) {
      calculateRoute();
    }
  }, [isLoaded]);

  const calculateRoute = async () => {
    if (!origin || !destination) {
      console.error("Origen o destino no definidos.");
      return;
    }

    const directionsService = new window.google.maps.DirectionsService();
    directionsService.route(
      {
        origin,
        destination,
        travelMode:  walking ? window.google.maps.TravelMode.WALKING : window.google.maps.TravelMode.DRIVING,
        optimizeWaypoints: true, // Asegura que se calcule la ruta más corta
      },
      (result, status) => {
        if (status === window.google.maps.DirectionsStatus.OK) {
          setDirectionsResponse(result);
        } else {
          console.error(`Error al calcular la ruta: ${status}`);
        }
      }
    );
  };

  if (loadError) {
    return <div>Error al cargar Google Maps: {loadError.message}</div>;
  }

  if (!isLoaded) {
    return <div>Cargando mapa...</div>;
  }

  return (
    <div
      style={{
        position: "fixed", // Pantalla completa
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        zIndex: 9999, // Asegura que esté en la parte superior
      }}
    >
      {/* Botón de cierre */}
      <button
        onClick={onClose}
        style={{
          position: "absolute",
          top: "10px",
          right: "10px",
          zIndex: 1000,
          padding: "10px 20px",
          backgroundColor: "#ff0000",
          color: "#fff",
          border: "none",
          borderRadius: "5px",
          cursor: "pointer",
        }}
      >
        Cerrar
      </button>

      {/* Mapa con la ruta */}
      <GoogleMap
        mapContainerStyle={{ width: "100%", height: "100%" }}
        center={{
          lat: (origin.lat + destination.lat) / 2,
          lng: (origin.lng + destination.lng) / 2,
        }}
        zoom={10}
      >
        {directionsResponse && <DirectionsRenderer directions={directionsResponse} />}
      </GoogleMap>
    </div>
  );
};

export default RouteMapModal;

