import React from "react";

const ActivityPage = () => {
  return (
    <div className="activities">
      <h1>Se procede a reservar</h1>
    </div>
  );
};

export default ActivityPage;
