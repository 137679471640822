import React, { useRef, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faSuitcase,
    faBed,
    faStreetView,
    faRunning,
    faMapMarkerAlt,
} from "@fortawesome/free-solid-svg-icons";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

const TabServices = ({ tabIndex, lang }) => {
    const containerRef = useRef(null);
    const { t, i18n } = useTranslation();
    const navigate = useNavigate();
    let isDragging = false;
    let startX;
    let scrollLeft;

    useEffect(() => {
        if (lang && i18n.language !== lang) {
            i18n.changeLanguage(lang); // Cambia el idioma dinámicamente
        }
    }, [lang, i18n]);

    const handleStart = (e) => {
        isDragging = true;
        startX = e.touches ? e.touches[0].pageX : e.pageX - containerRef.current.offsetLeft;
        scrollLeft = containerRef.current.scrollLeft;
        containerRef.current.style.cursor = "grabbing";
        document.body.style.userSelect = "none"; // Evita la selección de texto
    };

    const handleMove = (e) => {
        if (!isDragging) return;
        const x = e.touches ? e.touches[0].pageX : e.pageX - containerRef.current.offsetLeft;
        const walk = x - startX;
        containerRef.current.scrollLeft = scrollLeft - walk;
    };

    const handleEnd = () => {
        isDragging = false;
        containerRef.current.style.cursor = "grab";
        document.body.style.userSelect = ""; // Habilita la selección de texto nuevamente
    };

    const handleTabClick = (path) => {
        // Navegar a la ruta específica con el idioma en la URL
        navigate(`/${path}?lang=${lang}`);
      };

    const styles = {
        container: {
            display: "flex",
            flexDirection: "row",
            overflow: "hidden",
            whiteSpace: "nowrap",
            cursor: "grab",
            userSelect: "none", // Evita la selección dentro del contenedor
        },
        tab: (isSelected) => ({
            display: "flex",
            flexDirection: "column",
            borderRadius: "8px",
            margin: "0 4px",
            padding: "16px",
            backgroundColor: isSelected ? "white" : "transparent",
            border: isSelected ? "1px solid #ccc" : "1px solid transparent",
            boxShadow: isSelected ? "0 2px 4px rgba(0, 0, 0, 0.1)" : "none",
            cursor: "pointer",
        }),
        icon: {
            fontSize: "24px",
            textAlign: "center",
        },
        label: {
            textAlign: "center",
            fontWeight: "bold",
            fontSize: "14px",
        },
    };

    return (
        <div
            ref={containerRef}
            className="col-xxl-8 col-xl-8 col-lg-8 col-md-8 col-sm-8 col-xs-12 mt-1"
            style={styles.container}
            onMouseDown={handleStart}
            onMouseMove={handleMove}
            onMouseUp={handleEnd}
            onMouseLeave={handleEnd}
            onTouchStart={handleStart}
            onTouchMove={handleMove}
            onTouchEnd={handleEnd}
        >
            {/* Tab for Tours */}
            <div style={styles.tab(tabIndex === 0)} onClick={() => handleTabClick("travel-agencies")}>
                <div style={styles.icon}>
                    <FontAwesomeIcon icon={faSuitcase} />
                </div>
                <div style={styles.label}>{t("tab_index_1")}</div>
            </div>

            {/* Tab for Accommodations */}
            <div style={styles.tab(tabIndex === 1)} onClick={() => handleTabClick("accomodations")}
            >
                <div style={styles.icon}>
                    <FontAwesomeIcon icon={faBed} />
                </div>
                <div style={styles.label}>{t("tab_index_2")}</div>
            </div>

            {/* Tab for Restaurants */}
            {/*<div style={styles.tab(tabIndex === 2)}>
                <div style={styles.icon}>
                    <FontAwesomeIcon icon={faStreetView} />
                </div>
                <div style={styles.label}>{t("tab_index_3")}</div>
            </div>*/}

            {/* Tab for Activities */}
            {/*<div style={styles.tab(tabIndex === 3)}>
                <div style={styles.icon}>
                    <FontAwesomeIcon icon={faRunning} />
                </div>
                <div style={styles.label}>{t("tab_index_4")}</div>
            </div>*/}

            {/* Tab for Destinations */}
            {/*<div style={styles.tab(tabIndex === 4)}>
                <div style={styles.icon}>
                    <FontAwesomeIcon icon={faMapMarkerAlt} />
                </div>
                <div style={styles.label}>{t("tab_index_5")}</div>
            </div>*/}
        </div>
    );
};

export default TabServices;
