import { createSlice } from "@reduxjs/toolkit";

const locationSlice = createSlice({
  name: "location",
  initialState: {
    latitude: null,
    longitude: null,
    error: null,
  },
  reducers: {
    setLocation: (state, action) => {
      state.latitude = action.payload.latitude;
      state.longitude = action.payload.longitude;
    },
    setLocationError: (state, action) => {
      state.error = action.payload;
    },
  },
});

export const { setLocation, setLocationError } = locationSlice.actions;

export default locationSlice.reducer;