import { configureStore } from "@reduxjs/toolkit";
import locationReducer from "./slices/locationSlice";
import searchReducer from "./slices/searchSlice";

const store = configureStore({
  reducer: {
    location: locationReducer,
    search: searchReducer,
  },
});

export default store;
