import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import BusinessRepository from "../repositories/BusinessRepository";

const useBusinesses = (g_id = 1) => {
  const { latitude, longitude } = useSelector((state) => state.location);
  const { skip, limit, sort } = useSelector((state) => state.search);
  const [businesses, setBusinesses] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchBusinesses = async () => {
      setLoading(true);
      try {
        // Llamada a la API
        const response = await BusinessRepository.getAllBusinesses({
          lat: latitude,
          lng: longitude,
          skip,
          limit,
          sort,
          g_id,
        });
        console.log("API Response:", response);

        // Concatenar si skip > 0, si no, setear
        setBusinesses((prev) => {
          if (skip === 0) {
            return response || [];
          } else {
            return [...prev, ...(response || [])];
          }
        });

      } catch (err) {
        console.error("Error fetching businesses:", err);
        setError(err);
      } finally {
        setLoading(false);
      }
    };

    fetchBusinesses();
  }, [latitude, longitude, skip, limit, sort]); 
  // Importante: agregar [skip, limit, sort] en el array de dependencias
  
  return { businesses, loading, error };
};

export default useBusinesses;
