import React from "react";
import useBusinesses from "../../hooks/useBusinesses";
import { useDispatch, useSelector } from "react-redux"; // <-- Importar useDispatch y useSelector
import { setSearch } from "../../store/slices/searchSlice";    // <-- Ajusta la ruta y el nombre según tu archivo
import { useSearchParams } from "react-router-dom";
import Establecimiento from "../../components/Establecimiento/Establecimiento";
import Loading from "../../components/Loading";
import TabServices from "../../components/TabServices";
import NavbarHeader from "../../components/NavbarHeader";

const TravelAgencyPage = () => {
  const dispatch = useDispatch();
  const [searchParams, setSearchParams] = useSearchParams();

  const allowedLangs = ["es", "en", "fr","cn"]; // Valores permitidos
  let lang = searchParams.get("lang");

  // Validar el valor de lang
  if (!allowedLangs.includes(lang)) {
    lang = "es"; // Valor predeterminado
    setSearchParams({ lang: "es" }); // Actualiza la URL para incluir el valor predeterminado
  }

  // Obtenemos skip y limit del store para usarlos al cargar más
  const { skip, limit } = useSelector((state) => state.search);
  const { businesses, loading, error } = useBusinesses();
  
 
  const handleLoadMore = () => {
    // Actualizas el skip sumándole el valor de limit (para la siguiente página)
    dispatch(
      setSearch({
        skip: skip +1, // Importante: skip va aumentando en bloques de 'limit'
        limit: limit,
        sort: "ASC", // Mantén el sort si es necesario
      })
    );
  }

  if (loading) return <Loading isLoading={true} />;
  
  if (error) {return (<p>No se pudieron cargar los establecimientos. Por favor, intenta nuevamente más tarde.</p>);}

  return (
    <div className="container-fluid">
      {/* Navbar */}
      <NavbarHeader lang={lang} />
      
      {/* Espaciado */}
      <div className="mt-1"></div>
      <div className="row justify-content-center">
        <TabServices tabIndex={0} lang={lang} />
      </div>

      {/* Lista de establecimientos */}
      <div className="row justify-content-center">
        {businesses.map((business) => (
          <Establecimiento business={business} apiKey={business.id} lang={lang}/>
        ))}
      </div>

      {/* Espaciado */}
      <div className="mt-1"></div>
      
      {/* Espaciado */}
      <div className="row justify-content-center">
        <button className="btn btn-outline-dark w-100 p-2" onClick={handleLoadMore}>Cargar más</button>
      </div>
    
    </div>
  );
};

export default TravelAgencyPage;
